.wrapper {
    height: 70px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
    z-index: 9999;
    transition: top 0.3s;
}
.logo {
    font-size: 30px;
    color: white;
    font-weight: 900;
    cursor: pointer;
}
.leftW {
    width: 40%;
    display: flex;
    justify-content: space-around;
    font-size: 20px;
    font-weight: 900;
    color: white;
}
.items {
    cursor: pointer;
    position: relative;
    padding: 3px;
}
.items:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #6D9886;
    transform-origin: bottom right;
    transition: transform 0.15s ease-out;
  }
  .menu1 {
    display: none;
    border: 1px solid rgb(255, 255, 255);
    width: 28px;
    transition: transform 0.3s ease-out;
  }
  .menu2 {
    display: none;
    border: 1px solid rgb(255, 255, 255);
    width: 23px;
    transition: transform 0.4s ease-out;
    margin-top: 5px;
  }
  .items:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
.menuIt {
    height: 18px;
    display: flex;
    flex-direction: column; 
    z-index: 999;   
}
.anim1 {
    transform: rotate(-45deg);
    border: 1px solid rgb(0, 0, 0);
}
.anim2 {
    transform: rotate(45deg);
    margin-top: 0px;
    width: 28px;
    border: 1px solid rgb(0, 0, 0);
}
.hamWrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(500px);
    background-color: #6D9886;
    transition: transform 0.3s ease-in;
    height: 100%;
}
.hamIn {
    transform: translate(0);
}
.hamIt {
    font-size: 20px;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 1px solid black;
    display: flex;
    flex-direction: row-reverse;
}
  @media only screen and (max-width: 600px) {
    .wrapper {
        padding-left: 5%;
        padding-right: 5%;
    }
    .leftW {
        display: none;
    }
    .logo {
        font-size: 22px;
    }
    .menu1 {
        display: block;
    }
    .menu2 {
        display: block;
    }
    .hamWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        right: -5px;
        box-sizing: border-box;
        padding: 30px;
        justify-content: space-between;
        align-items: center;
    }
  }
  .logoHam {
    font-size: 24px;
    color: white;
    font-weight: 900;
    cursor: pointer;
    margin-bottom: 100px;
    box-sizing: border-box;
  }