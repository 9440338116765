.wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: black;
    align-items: center;
}
.title {
    font-size: 50px;
    color: white;
}
.frm {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 60%;
    background-color: white;
    height: 60%;
    padding: 50px;
    box-sizing: border-box;
}
.inputs {
    border: none;
    outline: none;
    height: 70px;
    width: 100%;
    font-size: 25px;
    transition: all 0.2s linear;
    box-sizing: border-box;
  }
  .inputs:focus {
    border-bottom: 1px solid black;
    font-size: 33px;
  }
  .btn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100px;
    background-color: black;
    color: white;
    font-size: 15px;
    font-weight: 600;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  .btn:hover {
    transform: scaleX(1.1);
  }
  .titleWrapper {
    margin-top: 100px;
    width: 60%;
  }

  @media only screen and (max-width: 600px) {
    .title {
      font-size: 30px;
      width: 100%;
      box-sizing: border-box;
      padding-left: 10%;
    }
    .titleWrapper {
      margin-top: 90px;
      width: 100%;
    }
    .frm {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      width: 80%;
      background-color: white;
      height: 60%;
      padding: 20px;
      box-sizing: border-box;
    }
    .inputs {
      border: none;
      outline: none;
      height: 80px;
      width: 100%;
      font-size: 19px;
      transition: all 0.2s linear;
      box-sizing: border-box;
    }
    .inputs:focus {
      font-size: 22px;
    }
  }