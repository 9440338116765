.inset {
  position: absolute;
  inset: 0;
}
.white {
  color: white;
}
.gray {
  color: #446;
}

.full {
  height: 100vh;
}

.center {
  display: flex;
  justify-content: center;
}

.gradient {
  background: linear-gradient(rgba(14, 62, 151, 0.5) 50%, black);
}
.headline {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 5vw;
}
.container {
  display: flex;
  justify-content: center;
}

.title {
  font-size: 5vw;
  font-weight: 800;
  color: white;
  padding: 10px;
  z-index: 999;
}
/* background-image: url('../images/manikarn.jpeg');
background-size: cover;
box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */

.dvWrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  padding: 50px;
  box-sizing: border-box;
}

.div1 {
  grid-area: 1 / 2 / 2 / 4;
}
.div2 {
  grid-area: 1 / 1 / 3 / 2;
  z-index: 999;
  background-image: url("../images/shimla.webp");
  background-size: cover;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 20px;
  border-radius: 10px;
  height: 500px;
  width: 400px;
}
.div3 {
  grid-area: 3 / 2 / 5 / 3;
  background-image: url("../images/waterF.webp");
  background-size: cover;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  height: 300px;
  width: 200px;
}
.div4 {
  grid-area: 2 / 3 / 4 / 4;
  background-image: url("../images/manali.webp");
  z-index: 99;
  background-size: cover;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  height: 300px;
  width: 300px;
}
.div5 {
  grid-area: 1 / 4 / 3 / 5;
  background-image: url("../images/rajas.webp");
  background-size: cover;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  height: 300px;
  width: 300px;
}
.div6 {
  grid-area: 4 / 1 / 6 / 2;
  background-image: url("../images/mahaB.webp");
  z-index: 999;
  background-size: cover;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  height: 400px;
  width: 400px;
}
.div7 {
  grid-area: 4 / 4 / 6 / 5;
  background-image: url("../images/khandar.webp");
  background-size: cover;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  height: 200px;
  width: 200px;
}

.mDiv {
  background-color: #d9cab3;
  position: relative;
}
.abs {
  font-size: 70px;
  font-weight: 600;
  z-index: 999;
  color: #6D9886;
}
@media only screen and (max-width: 600px) {
  
}