.wrapper {
    width: 100%;
    height: 25vh;
    background-color: #D9CAB3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-left: 70px;
    padding-right: 70px;
    border-top: 1px solid #6D9886;
}
.follow {
    font-size: 40px;
}
.secs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    box-sizing: border-box;
}
.fts {
    cursor: pointer;
}
.fts:hover {
    text-decoration: underline;
}
.title {
    font-size: 30px;
}
.cpy {
    font-size: 15px;
}
.links {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
@media only screen and (max-width: 600px) {
    .wrapper {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        flex-direction: column;
        align-items: flex-start;
    }
    .title {
        font-size: 20px;
    }
    .cpy {
        font-size: 10px;
    }
    .follow {
        font-size: 23px;
    }
    .secs {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .links {
        width: 50%;
    }
}
