.wrapper {
    width: 100%;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 8px;
}
  
  /* Create four equal columns that sits next to each other */
  .column {
    -ms-flex: 32%; /* IE10 */
    flex: 32%;
    max-width: 32%;
    padding: 0 8px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
    border-radius: 10px;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }
  .title {
    font-size: 3vw;
    font-weight: 800;
    color: white;
    z-index: 999;
    padding-left: 40px;
    box-sizing: border-box;
    width: 100%;
  }
  @media only screen and (max-width: 600px) {
  .title {
    font-size: 35px;
  }
  }