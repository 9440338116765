.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: black;
    align-items: center;
}
.title {
    font-size: 70px;
    color: white;
    font-weight: 900;
    margin-top: 100px;
    margin-bottom: 20px;
    padding-left: 100px;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
  }
  .servicesWrapper {
    width: 80%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
  }
  .inside {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .txt {
    color: white;
    font-size: 40px;
    transition: all 0.3s ease;
  }
  .names {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: left;
    justify-content: space-around;
    margin-left: 8vw;
    cursor: pointer;
  }
  .imgs {
    height: 100%;
    width: 100%;
  }
  .im{
    height: 80%;
    width: 100%;
  }
  .txt:hover {
    transform: scale(1.5);
  }
  .sc{
    transform: scale(1.5);
  }
  @media only screen and (max-width: 600px) {
    .title {
      font-size: 40px;
      margin-top: 50px;
      margin-bottom: 0;
      padding-left: 30px;
      width: 100%;
      text-align: left;
      box-sizing: border-box;
    }
    .servicesWrapper {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
    }
    .names {
      margin-top: 30px;
      flex-direction: row;
      margin-left: 0;
      margin-bottom: 20px;
    }
    .txt {
      font-size: 20px;
      transition: none;
    }
    .imgs {
      height: 90%;
      width: 100%;
    }
    .im{
      height: 70%;
      width: 100%;
    }
    .sc {
      transform: scale(1);
      border-bottom: 0.5px solid white;
    }
    .txt:hover {
      transform: scale(1);
      border-bottom: 0.5px solid white;
    }
  }