.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 450px;
    box-sizing: border-box;
    position: relative;
  }
  @media only screen and (max-width: 600px) {
    .swiper-slide {
      width: 150px;
    height: 250px;
    }
  }