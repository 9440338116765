
.container {
    width: 80%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: transparent;
    border-radius: 30px;
    margin-top: 100px;
    position: absolute;
    z-index: 99;
}
.title {
    font-size: 70px;
    font-weight: bolder;
    color: white;
}
.missionS {
    font-size: 30px;
}
.btn{
    /* background: linear-gradient(to left, #464b5d 50%, white 50%) right;
    background-size: 200% 100%;
    transition: .3s ease-out;
    padding: 12px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    cursor: pointer;
    color: white;
}
/* .btn:hover {
    background-position: left;
    color: black;
} */
.search {
    position: absolute;
    border: 1px solid black;
    width: 60%;
    height: 80px;
    bottom: -30px;
    background-color: white;
    border-radius: 10px;
}
@media only screen and (max-width: 600px) {
    .title {
        font-size: 50px;
        font-weight: bolder;
        color: white;
    }
    .missionS {
        font-size: 24px;
    }
    .btn {
        box-sizing: border-box;
        font-size: 25px;
    }
}

.arrows {
    width: 60px;
    height: 72px;
    margin-top: 20px;
  }
  
  .arrows path {
    stroke: white;
    fill: transparent;
    stroke-width: 1px;  
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite; 
  }
  
  @keyframes arrow
  {
  0% {opacity:0}
  40% {opacity:1}
  80% {opacity:0}
  100% {opacity:0}
  }
  
  @-webkit-keyframes arrow /*Safari and Chrome*/
  {
  0% {opacity:0}
  40% {opacity:1}
  80% {opacity:0}
  100% {opacity:0}
  }
  
  .arrows path.a1 {
    animation-delay:-1s;
    -webkit-animation-delay:-1s; /* Safari 和 Chrome */
  }
  
  .arrows path.a2 {
    animation-delay:-0.5s;
    -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
  }
  
  .arrows path.a3 { 
    animation-delay:0s;
    -webkit-animation-delay:0s; /* Safari 和 Chrome */
  }